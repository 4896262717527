import React from "react";
import { BannerThanks, Layout, SEO } from "../components";

const ThankYouPage = () => (
  <Layout>
    <SEO title="Home" />
    <BannerThanks
      title={`Thanks for signing up!`}
      subtitle={
        "We are still under construction and will keep you updated with our progress."
      }
      noForm={true}
    />
  </Layout>
);

export default ThankYouPage;
